import request from '../axios'

/**
 * 部门日程
 * @param { object } params
 * @param { string } params.start_date
 * @param { string } params.end_date
 */
export function getDeptScheduleList(params) {
  return request({
    // url: '/api/user/schedules/all',
    url: '/api/personnel/schedules',
    params,
  })
}

/**
 * 我的日程列表
 * @param { object } params
 * @param { string } params.start_date
 * @param { string } params.end_date
 */
export function getMyScheduleList(params) {
  return request({
    url: '/api/my/personnel/schedules/list',
    params,
  })
}

/**
 * 新建日程
 * @param { object } params
 * @param { object } params.schedule_time
 * @param { object } params.content
 */
export function createSchedule(params) {
  return request({
    method: 'post',
    url: '/api/personnel/schedules',
    data: params,
  })
}

/**
 * 修改日程
 * @param { object } params
 * @param { object } params.schedule_time
 * @param { object } params.content
 */
export function updateSchedule(id, params) {
  return request({
    method: 'post',
    url: `/api/personnel/schedules/${id}`,
    data: params,
  })
}

/**
 * 删除日程
 * @param { object } id
 */
export function deleteSchedule(id) {
  return request({
    method: 'post',
    url: `/api/personnel/schedules/del/${id}`,
  })
}

// ============================== 流程、要点、制度 ==============================
/**
 * 分页
 */
export function getRecodsPage(params) {
  return request({
    url: '/api/recods',
    params,
  })
}

/**
 *  创建
 */
export function createRecods(params) {
  return request({
    method: 'post',
    url: '/api/recods',
    data: params,
  })
}

/**
 *  详情
 */
export function getRecods(id) {
  return request({
    url: `/api/recods/${id}`,
  })
}

/**
 *  详情
 */
export function deleteRecods(id) {
  return request({
    method: 'post',
    url: `/api/recods/del/${id}`,
  })
}

// ============================== 工作清单 ==============================
/**
 * 查询部门下子部门工作清单
 * @param { string } id
 * @param { object } params
 * @param { string } params.start_date
 * @param { string } params.end_date
 */
export function getDeptWorkList(id, params) {
  return request({
    url: `/api/detailed/list/children/department/state/${id}`,
    params,
  })
}

/**
 * 部门工作清单详情
 * @param { object } params
 * @param { string } params.department_id
 * @param { string } params.start_date
 * @param { string } params.end_date
 */
export function getDeptWork(params) {
  return request({
    url: '/api/detailed/list/department',
    params,
  })
}

/**
 * 新建 部门工作清单
 * @param { object } params
 * @param { string } params.week_summary
 * @param { string } params.next_week_plan
 * @param { string } params.year
 * @param { string } params.month
 * @param { string } params.week
 */
export function createDeptWork(params) {
  return request({
    method: 'post',
    url: '/api/detailed/list/department',
    data: params,
  })
}

/**
 * 修改 部门工作清单
 * @param { string } id
 * @param { object } params
 * @param { string } params.week_summary
 * @param { string } params.next_week_plan
 */
export function updateDeptWork(id, params) {
  return request({
    method: 'post',
    url: `/api/detailed/list/department/${id}`,
    data: params,
  })
}

/**
 * 查询部门下人员工作清单
 * @param { string } id
 * @param { object } params
 * @param { string } params.start_date
 * @param { string } params.end_date
 */
export function getUserWorkList(id, params) {
  return request({
    url: `/api/detailed/list/department/users/state/${id}`,
    params,
  })
}

/**
 * 查询人员工作清单
 * @param { string } id
 * @param { object } params
 * @param { string } params.start_date
 * @param { string } params.end_date
 */
export function getUserWork(id, params) {
  return request({
    url: `/api/detailed/list/user/${id}`,
    params,
  })
}

/**
 * 新建人员工作清单
 * @param { object } params
 * @param { string } params.week_summary - 本周总结
 * @param { string } params.next_week_plan - 下周规划
 */
export function createUserWork(params) {
  return request({
    method: 'post',
    url: '/api/detailed/list/user',
    data: params,
  })
}

/**
 * 修改人员工作清单
 */
export function updateUserWork(id, params) {
  return request({
    method: 'post',
    url: `/api/detailed/list/user/${id}`,
    data: params,
  })
}

// ============================== 任务反馈管理 ==============================
/**
 *  获取部门任务统计
 */
export function getDeptFeedbackPage(id, params) {
  return request({
    url: `/api/children/department/feedback/${id}`,
    params,
  })
}

/**
 * 部门的任务
 */
export function getDeptTaskPage(id, params) {
  return request({
    url: `/api/department/${id}/feedback/agenda/list`,
    params,
  })
}

/**
 *  获取用户任务统计
 */
export function getUserFeedbackPage(id, params) {
  return request({
    url: `/api/department/users/feedback/${id}`,
    params,
  })
}

/**
 * 用户的任务
 */
export function getUserTaskPage(id, params) {
  return request({
    url: `/api/user/${id}/feedback/agenda/list`,
    params,
  })
}
