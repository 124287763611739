<!--
 * @Author: jiang
 * @Date: 2021-07-13 16:40:49
 * @Description:
-->
<template>
  <div class="schedule-page">
    <aside class="aside g-bg">
      <el-calendar v-model="date">
        <template
          slot="dateCell"
          slot-scope="{data}"
        >
          <div :class="{'date-item': true, active: handlerDateItem(data.day)}">
            <div class="num">{{ data.day.split('-')[2] }}</div>
          </div>
        </template>
      </el-calendar>
    </aside>

    <main class="main g-bg">
      <el-scrollbar
        y
        view-style="padding: 15px;"
      >
        <div class="time-box">
          <span>{{ year }}年</span>
          <span>{{ month }}月</span>
          <span>{{ day }}日</span>
        </div>
        <div
          v-if="currentList.length === 0"
          class="g-empty"
        ></div>
        <div v-else>
          <div
            class="list-item"
            v-for="item in currentList"
            :key="item.id"
          >
            <div class="content">
              <el-tooltip
                disabled
                content=""
              >
                <div class=" g-text-ellipsis">
                  {{ item.content }}
                </div>
              </el-tooltip>
            </div>
            <el-dropdown @command="onCommand($event, item)">
              <span class="el-dropdown-link">
                <i class="el-icon-more"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  command="update"
                  icon="el-icon-edit"
                >
                  修改
                </el-dropdown-item>
                <el-dropdown-item
                  command="delete"
                  icon="el-icon-delete"
                >
                  删除
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>

        <el-button
          type="primary"
          block
          plain
          icon="el-icon-plus"
          @click="onCreate"
        >新建日程</el-button>
      </el-scrollbar>
    </main>

    <b-dialog ref="detailDialog">
      <detail-view
        :data="formData"
        code="department"
        @success="onDetailSuccess"
      ></detail-view>
    </b-dialog>
  </div>
</template>

<script>
import DetailView from '../components/schedule-detail.vue'
import { deleteSchedule, getDeptScheduleList } from '@/api/manager/index'

export default {
  components: {
    DetailView,
  },
  data() {
    return {
      date: new Date(),
      formData: {},
      list: [],
      start_date: '',
      end_date: '',
    }
  },
  computed: {
    currentList() {
      return this.list.filter(
        item => item.schedule_time === this.$tools.dayjs(this.date).format('YYYY-MM-DD'),
      )
    },
    departmentId() {
      return this.$store.state.user.userInfo.department_id
    },
    year() {
      return this.$tools.dayjs(this.date).format('YYYY')
    },
    month() {
      return this.$tools.dayjs(this.date).format('MM')
    },
    day() {
      return this.$tools.dayjs(this.date).format('DD')
    },
  },
  watch: {
    date: {
      handler(val) {
        const start = this.$tools.dayjs(val).date(1).format('YYYY-MM-DD')
        const end = this.$tools.dayjs(val).add(1, 'month').date(0).format('YYYY-MM-DD')
        if (start === this.start_date && end === this.end_date) return
        this.start_date = start
        this.end_date = end
        this.fetchList()
      },
      immediate: true,
    },
  },
  methods: {
    fetchList() {
      const params = {
        start_date: this.start_date,
        end_date: this.end_date,
      }
      // getMyScheduleList(params).then(res => {
      //   this.list = res
      // })

      getDeptScheduleList({
        ...params,
        type: 'department',
        department_id: this.departmentId,
      }).then(res => {
        this.list = res.data
      })
    },
    onDetailSuccess() {
      this.$refs.detailDialog.close()
      this.fetchList()
    },
    onCreate() {
      this.formData = {
        schedule_time: this.$tools.dayjs(this.date).format('YYYY-MM-DD'),
        content: '',
        department_id: this.departmentId,
      }
      this.$refs.detailDialog.open()
    },
    onCommand(command, row) {
      if (command === 'update') {
        this.formData = {
          id: row.id,
          schedule_time: row.schedule_time,
          content: row.content,
          department_id: row.department_id,
        }
        this.$refs.detailDialog.open()
      } else {
        this.$confirm('确定要删除当前项吗', { type: 'warning' })
          .then(res => {
            return deleteSchedule(row.id)
          })
          .then(res => {
            this.$message.success('删除成功')
            this.fetchList()
          })
          .catch(() => {})
      }
    },
    handlerDateItem(day) {
      return this.list.findIndex(item => item.schedule_time === day) > -1
    },
  },
}
</script>
<style lang="scss" scoped>
.schedule-page {
  display: flex;
  height: 100%;
  padding: 0 15px 15px;

  .aside {
    flex: 2;
    min-width: 0;
    margin-right: 15px;
  }

  .main {
    flex: 1;
    min-width: 0;
  }

  .time-box {
    height: 44px;
    margin-top: -10px;
    font-size: 16px;
    font-weight: 600;
    line-height: 44px;
  }

  .date-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    &.active::after {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 10px;
      height: 10px;
      content: '';
      background: #17c167;
      border-radius: 50%;
      transform: translate(-50%, 20px);
    }
  }

  /deep/ .is-selected .date-item .num {
    width: 32px;
    height: 32px;
    line-height: 32px;
    color: #fff;
    text-align: center;
    background: #04a37e;
    border-radius: 50%;
  }

  .list-item {
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0 15px;
    margin-bottom: 15px;
    background: rgba($color: #dfe3e8, $alpha: 0.26);
    border-radius: 10px;

    .content {
      flex: 1;
      min-width: 0;
      margin-right: 20px;
      font-size: 14px;
      font-weight: bold;
      color: #2b2d42;
    }
  }
}
</style>
